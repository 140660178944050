
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { deleteClaim, getClaim, updateClaim } from "@/api/claim.api";
import { useRouter } from "vue-router";
import { getDictionary } from "@/api/dictionaries.api";
import Multiselect from "@vueform/multiselect";
import { Claim } from "@/modules/claims/claim.model";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { getPanel } from "@/api/encounter.api";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface Data {
  isDirty: boolean;
  claimPrevious?: Claim;
  panel: any;
  claim?: Claim;
  patient: unknown;
  dict: {
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    admissionType: [];
    admissionSource: [];
    dischargeStatus: [];
    claimStatuses: [];
  };
}

export default defineComponent({
  name: "ClaimComponent",
  props: ["claim", "claimId"],
  components: { Multiselect },
  setup(props, ctx) {
    const store = useStore();

    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      panel: { facilities: [], patients: [], providers: [] },
      patient: undefined,
      claim: undefined,
      dict: {
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
        claimStatuses: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.panel = await getPanel();
      data.panel.facilities = data.panel.facilities.filter((c) => {
        return c.billingProvider;
      });
      data.panel.providers = data.panel.providers.filter((c) => {
        return c.attending;
      });
      await getDict();

      data.dict.claimStatuses = store.getters.allClaimStatuses;
      let claim = props.claim;
      if (!claim) {
        claim = await getClaim(props.claimId);
      }
      data.patient = claim.encounter.patient;
      data.claim = claim;
      data.claimPrevious = JSON.parse(JSON.stringify(data.claim));
    });

    const npiReg = helpers.regex(/\d{10}/);
    const taxIdReg = helpers.regex(/\d{9}/);
    const lastName = helpers.regex(/^.{3,}$/);

    const claimRules = {
      claim: {
        admissionType: { required:helpers.withMessage("Required", required) },
        admissionSource: { required:helpers.withMessage("Required", required) },
        dischargeStatus: { required:helpers.withMessage("Required", required) },
        documentControlNumber: { required:helpers.withMessage("Required", required) },
        remarksField: { required:helpers.withMessage("Required", required) },
        encounterId: { required:helpers.withMessage("Required", required) },
        facilityBillingProviderId: { required:helpers.withMessage("Required", required) },
        billingProviderId: { required:helpers.withMessage("Required", required) },
        payerId: { required:helpers.withMessage("Required", required) },
        typeOfFacility: { required:helpers.withMessage("Required", required) },
        typeOfCare: { required:helpers.withMessage("Required", required) },
        frequency: { required:helpers.withMessage("Required", required) },
        taxId: {
          required:helpers.withMessage("Required", required),
          taxIdReg: helpers.withMessage("9 numbers required", taxIdReg),
        },
      },
    };

    const v$ = useVuelidate(claimRules, data as never);

    watch(
      () => data.claim,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.claimPrevious &&
          JSON.stringify(currentValue) != JSON.stringify(data.claimPrevious)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    async function getDict() {
      data.dict.typeOfFacility = await getDictionary(4, "");
      data.dict.typeOfCare = await getDictionary(5, "");
      data.dict.frequency = await getDictionary(6, "");
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
    }

    async function updateClaimData() {
      const result = await v$.value.claim.$validate();
      if (result) {
        const res = await updateClaim(data.claim);
        if (res) {
          data.claim = await getClaim(props.claimId);
          data.claimPrevious = JSON.parse(JSON.stringify(data.claim));
          data.isDirty = false;
          ctx.emit("claimUpdated");
          Swal.fire("Ok!", "Claim details saved successfully", "success");
        }
      } else {
        Swal.fire(
          "Error!",
          "All required values must be entered to save the claim details form",
          "error"
        );
      }
    }

    async function deleteClaimData() {
      await deleteClaim(data.claim);
      ctx.emit("claimDeleted");
    }

    async function cancel() {
      const res = await Swal.fire({
        title: "Are you sure you want to leave without saving changes?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      });
      if (res.isConfirmed) {
        data.claim = await getClaim(props.claimId);
        data.isDirty = false;
      }
    }

    function billingProviderChanged() {
      // if (data.claim) {
      //   const facility = data.panel.facilities.find((c) => {
      //     return c.id == data.claim?.facilityBillingProviderId;
      //   });
      //   data.claim.taxId = facility.taxId;
      // }
    }

    return {
      organizationId,
      billingProviderChanged,
      data,
      updateClaimData,
      deleteClaimData,
      cancel,
      v$,
    };
  },
});
